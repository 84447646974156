import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ArmazenamentoDeUsuarioService {
  public readonly chave = 'user';

  public armazenarUsuario(usuarioDados: string) {
    sessionStorage.setItem(this.chave, JSON.stringify(usuarioDados));
  }

  public buscarUsuario() {
    const usuarioDados = sessionStorage.getItem(this.chave);
    if (usuarioDados == 'undefined') {
      return null
    } else if (usuarioDados) {
      return JSON.parse(usuarioDados) 
    }
  }

  public removerUsuario() {
    return sessionStorage.removeItem(this.chave);
  }
}
