import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RespostaDaAPI } from 'src/app/shared/interfaces/resposta-da-api.interface';
import { DadosDoLogin } from 'src/app/shared/interfaces/dados-do-login.interface';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private readonly APIEntryPoints = environment.APIEntryPoint_1;

  constructor(private httpClient: HttpClient) {}

  private criarOpcoesDaRequisicao(mostrarAnimacaoDeCarregamento: boolean): {
    headers: HttpHeaders;
  } {
    const headers = new HttpHeaders().set(
      'X-Show-Loading-Animation',
      mostrarAnimacaoDeCarregamento.toString()
    );
    return { headers };
  }

  public login(
    dados: DadosDoLogin,
    mostrarAnimacaoDeCarregamento = true
  ): Observable<any> {
    const opcoes = this.criarOpcoesDaRequisicao(mostrarAnimacaoDeCarregamento);
    return this.httpClient.post<RespostaDaAPI>(
      `${this.APIEntryPoints}/auth/signin/gerenciamento`,
      dados,
      opcoes
    );
  }

  public logout(mostrarAnimacaoDeCarregamento = true): Observable<any> {
    const opcoes = this.criarOpcoesDaRequisicao(mostrarAnimacaoDeCarregamento);
    return this.httpClient.post<RespostaDaAPI>(
      `${this.APIEntryPoints}/logout`,
      undefined,
      opcoes
    );
  }

  public gerarCodigo(
    dados: DadosDoLogin,
    mostrarAnimacaoDeCarregamento = true
  ): Observable<any> {
    const opcoes = this.criarOpcoesDaRequisicao(mostrarAnimacaoDeCarregamento);
    return this.httpClient.post<RespostaDaAPI>(
      `${this.APIEntryPoints}/auth/forgot-password`,
      dados,
      opcoes
    );
  }

  public validarCodigo(
    dados: DadosDoLogin,
    mostrarAnimacaoDeCarregamento = true
  ): Observable<any> {
    const opcoes = this.criarOpcoesDaRequisicao(mostrarAnimacaoDeCarregamento);
    return this.httpClient.post<RespostaDaAPI>(
      `${this.APIEntryPoints}/auth/security-code-validation`,
      dados,
      opcoes
    );
  }

  public redefinirSenha(
    dados: DadosDoLogin,
    mostrarAnimacaoDeCarregamento = true
  ): Observable<any> {
    const opcoes = this.criarOpcoesDaRequisicao(mostrarAnimacaoDeCarregamento);
    return this.httpClient.post<RespostaDaAPI>(
      `${this.APIEntryPoints}/auth/password-recovery`,
      dados,
      opcoes
    );
  }
}
